import React from 'react';
import cn from 'classnames';
import { Breadcrumbs } from '@components/Blocks/Breadcrumbs';
import Layout from '@components/Layouts/Layout';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

import styles from './contacts.module.scss';

export function Head() {
  return (
    <>
      <SEO
        title="Контакты"
        description="Свяжитесь с нами любым способом: телефон, мессенджеры или email."
      />
      <YandexTableau />
    </>
  );
}

function Contacts() {
  const breadcrumbsPath = [
    { title: 'Главная', url: '/' },
    { title: 'Контакты', url: '' },
  ];

  return (
    <Layout>
      <div className={styles.breadcrumbsContainer}>
        <Breadcrumbs path={breadcrumbsPath} />
      </div>

      <div className={styles.contactsPageWrapper}>
        <h1 className={styles.title}>Контакты</h1>
        <div className={styles.contactsWrapper}>
          <div className={styles.clients}>
            Клиентский сервис с&nbsp;10 до&nbsp;22 <br /> каждый день
          </div>
          <div className={styles.contact}>
            Написать нам в&nbsp;
            <a
              className={styles.action}
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://wa.me/79686043008"
            >
              WhatsApp
            </a>
          </div>
          <div className={cn({ [styles.text]: true, [styles.contact]: true })}>
            <h3> E-mail </h3>
            <span className={styles.info}>
              Служба поддержки:&nbsp;
              <a className={styles.action} href="mailto:help@periodica.press">
                help@periodica.press
              </a>
            </span>
            <span className={styles.info}>
              Руководитель службы поддержки: <br />
              <a className={styles.action} href="mailto:careforyou@periodica.press">
                careforyou@periodica.press
              </a>
            </span>
          </div>
          <div className={cn({ [styles.text]: true, [styles.contact]: true })}>
            <h3>Telegram</h3>
            <a
              className={cn({ [styles.info]: true, [styles.action]: true })}
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://t.me/periodicasupport_bot"
            >
              @PeriodicaSupport_bot
            </a>
          </div>
          <div className={styles.text}>
            <h3>Сотрудничество</h3>
            <a
              className={cn({ [styles.info]: true, [styles.action]: true })}
              href="mailto:pr@periodica.press"
            >
              pr@periodica.press
            </a>
          </div>
          <div className={styles.text}>
            <h2> Реквизиты </h2>
            <span className={styles.requisites}>
              ООО «Периодика Пресс» <br /> ИНН 7743125098 <br /> ОГРН 1157746985285 <br />
              Юридический адрес: 125315, г. Москва, <br /> Ленинградский проспект, д. 74А
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contacts;
