import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';

import rightChevron from '@images/icons/right-chevron-gray.svg';

import styles from './Breadcrumbs.module.scss';

type PathItem = {
  title: string;
  url: string;
};

interface BreadcrumbsArgs {
  path: PathItem[];
}

export function Breadcrumbs({ path }: BreadcrumbsArgs) {
  return (
    <div className={styles.breadcrumbsContainer}>
      {path.map((item) => {
        const isLast = path.indexOf(item) === path.length - 1;
        return (
          <React.Fragment key={`breadcrumb_${item.title}`}>
            <Link
              to={item.url}
              className={cn({ [styles.breadcrumb]: true, [styles.lastItem]: isLast })}
            >
              {item.title}
            </Link>
            {!isLast && <img src={rightChevron} className={styles.icon} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
